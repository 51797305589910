
import CloudFun, { computed, defineComponent, ref } from '@cloudfun/core'
import Grid, { GridOptions } from '@/cloudfun/components/Grid.vue'
import { VxeFormProps } from 'vxe-table'
import CheckBoxList from '@/cloudfun/components/CheckBoxList.vue'

export default defineComponent({
  components: {
    Grid,
    // CheckBoxList
  },
  setup () {
    const model = CloudFun.current?.model
    const grid = ref<any>({})
    var printColumns = [{ field: 'Name' }, { field: 'Description' }]

    const gridOptions: GridOptions = {
      id: 'mapping1',
      title: 'Mapping_1',
      toolbarConfig: {
        custom: true,
        refresh: true
      },
      canDelete: false,
      canUpdate: false,
      canCreate: false,
      multiselect: false,
      printConfig: {
        sheetName: '清單',
        columns: printColumns,
        modes: ['current', 'selected', 'all']
      },
      exportConfig: {
        filename: '清單',
        type: 'csv',
        types: ['html', 'csv'],
        mode: 'all',
        modes: ['current', 'selected', 'all'],
        columns: printColumns
      },
      columns: [
        { field: 'ProductNo', title: '品號', showHeaderOverflow: true, showOverflow: true, sortable: true },
        {
          field: 'Name',
          title: '品名',
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
        },
        { field: 'CustomerNo', title: '客戶代號', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: 'CustomerSKU', title: '客戶SKU', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: 'Specifications', title: '規格', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: 'Color', title: '顏色', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: 'Package', title: '包裝方式', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: 'Boxes', title: '箱', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: 'Pallets', title: '板', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: 'NetWeight', title: '單箱淨重', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: 'GrossWeight', title: '單箱毛重', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: 'CartonSize', title: '外箱尺寸', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: 'CBM', title: 'CBM(立方米)', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: 'Cuft', title: 'Cuft(材)', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: 'ManufacturerCode', title: '廠商代號', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: 'ManufacturerShortName', title: '廠商簡稱', showHeaderOverflow: true, showOverflow: true, sortable: true },
        { field: 'ContainerType', title: '櫃型', showHeaderOverflow: true, showOverflow: true, sortable: true },
      ],
      promises: {
        query: model
          ? (params) => model.dispatch('mapping1/query', params)
          : undefined,
        queryAll: model ? () => model.dispatch('mapping1/query') : undefined,
        save: model
          ? (params) => model.dispatch('mapping1/save', params)
          : undefined
      },
      modalConfig: { width: "60%", showFooter: true }
    }

    const formOptions: VxeFormProps = {
      titleWidth: 100,
      titleAlign: 'right',
      items: [
        {
          field: 'ProductNo',
          title: '品號',
          span: 12,
          itemRender: {
            name: '$input',
            props: { placeholder: '請輸入文字', readonly: true },
            attrs: { type: 'text' }
          }
        },
        {
          field: 'Name',
          title: '品名',
          span: 12,
          itemRender: {
            name: '$input',
            props: { placeholder: '請輸入文字', readonly: true },
            attrs: { type: 'text' }
          }
        },
        {
          field: 'CustomerNo',
          title: '客戶代號',
          span: 12,
          itemRender: {
            name: '$input',
            props: { placeholder: '請輸入文字', readonly: true },
            attrs: { type: 'text' }
          }
        },
        {
          field: 'CustomerSKU',
          title: '客戶SKU',
          span: 12,
          itemRender: {
            name: '$input',
            props: { placeholder: '請輸入文字', readonly: true },
            attrs: { type: 'text' }
          }
        },
        {
          field: 'Specifications',
          title: '規格',
          span: 12,
          itemRender: {
            name: '$input',
            props: { placeholder: '請輸入文字', readonly: true },
            attrs: { type: 'text' }
          }
        },
        {
          field: 'Color',
          title: '顏色',
          span: 12,
          itemRender: {
            name: '$input',
            props: { placeholder: '請輸入文字', readonly: true },
            attrs: { type: 'text' }
          }
        },
        {
          field: 'Package',
          title: '包裝方式',
          span: 12,
          itemRender: {
            name: '$input',
            props: { placeholder: '請輸入文字', readonly: true },
            attrs: { type: 'text' }
          }
        },
        {
          field: 'Boxes',
          title: '箱',
          span: 12,
          itemRender: {
            name: '$input',
            props: { placeholder: '請輸入文字', readonly: true },
            attrs: { type: 'text' }
          }
        },
        {
          field: 'Pallets',
          title: '板',
          span: 12,
          itemRender: {
            name: '$input',
            props: { placeholder: '請輸入文字', readonly: true },
            attrs: { type: 'text' }
          }
        },
        {
          field: 'NetWeight',
          title: '單箱淨重',
          span: 12,
          itemRender: {
            name: '$input',
            props: { placeholder: '請輸入文字', readonly: true },
            attrs: { type: 'text' }
          }
        },
        {
          field: 'GrossWeight',
          title: '單箱毛重',
          span: 12,
          itemRender: {
            name: '$input',
            props: { placeholder: '請輸入文字', readonly: true },
            attrs: { type: 'text' }
          }
        },
        {
          field: 'CartonSize',
          title: '外箱尺寸',
          span: 12,
          itemRender: {
            name: '$input',
            props: { placeholder: '請輸入文字', readonly: true },
            attrs: { type: 'text' }
          }
        },
        {
          field: 'CBM',
          title: 'CBM(立方米)',
          span: 12,
          itemRender: {
            name: '$input',
            props: { placeholder: '請輸入文字', readonly: true, digits: 4 },
            attrs: { type: 'text' }
          }
        },
        {
          field: 'Cuft',
          title: 'Cuft(材)',
          span: 12,
          itemRender: {
            name: '$input',
            props: { placeholder: '請輸入文字', readonly: true },
            attrs: { type: 'text' }
          }
        },
        {
          field: 'ManufacturerCode',
          title: '廠商代號',
          span: 12,
          itemRender: {
            name: '$input',
            props: { placeholder: '請輸入文字', readonly: true },
            attrs: { type: 'text' }
          }
        },
        {
          field: 'ManufacturerShortName',
          title: '廠商簡稱',
          span: 12,
          itemRender: {
            name: '$input',
            props: { placeholder: '請輸入文字', readonly: true },
            attrs: { type: 'text' }
          }
        },
        {
          field: 'ContainerType',
          title: '櫃型',
          span: 12,
          itemRender: {
            name: '$input',
            props: { placeholder: '請輸入文字', readonly: true },
            attrs: { type: 'text' }
          }
        },
      ],
      // rules: {
      //   Name: [{ type: 'string', required: true }]
      // }
    }

    return {
      grid,
      gridOptions,
      formOptions,
    }
  },
  methods: {
    hideBatchDropDown () {
      cash('#batch-dropdown').dropdown('hide')
    }
  }
})
